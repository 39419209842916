import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { ReactComponent as Logo } from "theme/img/logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuItems: {
      marginRight: theme.spacing(2),
      color: "#fff",
      textDecoration: "none",
    },
    logo: {
      flexGrow: 1,
    },
  })
);

export default function Menu() {
  const classes = useStyles();

  const handleClick = () => {
    return window.open("https://assetscontrol.io/backoffice", "_blank")
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="transparent"
        style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <Toolbar className="toolbar">
          <a href="/" className={classes.logo}>
            <Logo />
          </a>
          <a href="/" className={classes.menuItems}>
            <Button
              className="btnBackOffice"
              onClick={handleClick}
              size="large"
            >
              Acessar Backoffice
            </Button>
          </a>
        </Toolbar>
      </AppBar>
    </div>
  );
}
