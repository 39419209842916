import React from "react";

import { Button } from "@material-ui/core";
import { Banner, Menu } from "components";
import Image01 from "theme/img/01.svg";
import Tag01 from "theme/img/tag.png";
import Image02 from "theme/img/02.svg";
import Image03 from "theme/img/03.svg";
import Image04 from "theme/img/04.svg";
import CloudImg from "theme/img/nuvem.png";
import CloudImgMobile from "theme/img/nuvem-mobile.png";
import LockerImg from "theme/img/cadeado.png";
import MarteloImg from "theme/img/martelo.png";
// import { ReactComponent as BarraImagens } from "theme/img/barra-imagens.svg";

import "./Home.scss";

const Home: React.FC = () => {
  return (
    <>
      <Menu />
      <Banner />
      <section id="info">
        <div className="content">
          <h2>Seu sistema de gestão de ativos e inventário</h2>
          <p>
            Seu inventário por RFID, <br /> QR-Code ou código de barras
          </p>
        </div>
      </section>

      <section id="vantagens">
        <div className="content">
          <div className="ilustration-head-mobile">
            <img src={CloudImgMobile} alt="cloud" />
          </div>
          <div className="ilustration-head">
            <img src={CloudImg} alt="cloud" />
          </div>
          <div className="row">
            <div className="text">
              <h2>Vantagens e controle</h2>
              <p>
                O AssetsControl.io oferece inúmeras vantagens, pois utiliza de
                toda infraestrutura em nuvem, garantindo dessa forma que o
                usuário não precise se preocupar com backups, armazenamento dos
                dados e muito menos gastar com servidores para deixar esses
                dados alocados em sua infraestrutura física. Além disso, o
                acesso poderá ser feito de qualquer local, seja nos computadores
                da empresa, tablets e celulares.
              </p>
            </div>
            <div className="ilustration">
              <img src={Image02} alt="Image02" />
            </div>
          </div>
        </div>
      </section>
      <section id="missao">
        <div className="content">
          <div className="item">
            <h2>Missão</h2>
            <p>
              Possibilitar uma gestão patrimonial de qualidade, segura, de alta
              performance e tecnologia, priorizando a ética e o sucesso de
              nossos clientes.
            </p>
          </div>
          <div className="item">
            <h2>Visão</h2>
            <p>
              Ser reconhecida como uma empresa que oferece a melhor opção no
              mercado em serviços voltados aos controles patrimoniais, com alta
              tecnologia, confiabilidade e melhor custo-benefício.
            </p>
          </div>
          <div className="item">
            <h2>Valores</h2>
            <p>
              Integridade, segurança, ética, sustentabilidade, inovação e
              respeito ao próximo.
            </p>
          </div>
        </div>
      </section>

      <section id="barra-imagens" />

      <section id="seguranca">
        <div className="content">
          <div className="header">
            <img src={Image03} alt="03" className="image03" />
          </div>
          <div className="info">
            <img src={LockerImg} alt="LockerImg" className="LockerImg" />
            <div className="text">
              <h2>Segurança</h2>
              <p>
                A segurança dos seus dados é garantida por meio de autenticações
                e autorizações. Desta forma somente estão autorizados a acessar
                quem realmente tiver permissão para isso. Os seus dados estão
                acessíveis somente para sua empresa, ou quem você autorizar.
                Ademais, os dados jamais são perdidos, uma vez que estão
                gravados e armazenados em nuvem.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="aspectos-legais">
        <div className="content">
          <div className="column01">
            <img src={Image04} alt="04" />
            <h2>Aspectos legais</h2>
            <p>
              Os serviços são realizados em conformidade com as NBCs (Normas
              Brasileiras de Contabilidade) emitidas pelo CFC (Conselho Federal
              de Contabilidade) e com a Legislação Tributária e Fiscal, também
              atendendo a todos os aspectos de proteção exigidos pela LGPD (Lei
              Geral de Proteção de Dados) e suportando os aspectos gerenciais da
              ISO 55000 (gestão de Ativos) e suas complementares.
            </p>
          </div>
          <div className="column02">
            <img src={MarteloImg} alt="MarteloImg" />
          </div>
        </div>
      </section>

      <section id="contato">
        <div className="content">
          <h2>
            Entre em contato <br />e tire as suas dúvidas.
          </h2>
          <Button
            style={{
              backgroundColor: "transparent",
              border: "1px solid #fff",
              padding: "10px 20px",
              textTransform: "none",
            }}
            onClick={() => {
              window.open("mailto:contato@assetscontrol.io");
            }}
          >
            solicite um orçamento
          </Button>
        </div>
      </section>

      <section id="footer">
        {/* <div className="content"> */}
        <div className="contact">
          <p>contato@assetscontrol.io</p>
          <p>(19) 3255-0501</p>
        </div>
        <div className="about">
          <p>
            Assets Control é uma empresa <br />
            especializada em sistemas de gestão <br />
            de ativos, inventários e controle <br />
            patrimonial. Estamos sediados em <br />
            Campinas, SP.
          </p>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Home;
